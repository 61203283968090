import { useMemo } from 'react'
import { useRouter } from 'next/router'
import times from 'lodash.times'
import {
  IContentfulBasicEntry,
  isValidArrayWithData,
  useCoupons,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Backbone,
  BannersLayoutEnum,
  Column,
  Icon,
  Row
} from '@smu-chile/pkg-unimarc-components'
import PromotionsCarousel from './PromotionsCarousel'
import { getBannersCarousel } from './helpers/clubAhorroContent'
import { IAlliance } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlliance'
import {
  BannersClubAhorro,
  ClubUnimarcBannerLabels,
  ClubUnimarcBannerLayout
} from 'shared/interfaces/ClubUnimarc'
import { ClubAhorroCoupons } from '../ClubAhorroCoupons'
import { slugify } from 'shared/helpers/slugify'
import { ClubFeatureGrid } from 'components/ClubPage/components/features/ClubFeatureGrid'
import { ClubBlock } from 'components/ClubPage/components/blocks/ClubBlock'
import { CollageBanner } from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/CollageBanner'
import { ClubFeatureCollage } from 'components/ClubPage/components/features/ClubFeatureCollage'
import { IMembershipBlock } from 'shared/interfaces/IMembership'
import { ClubBanner } from './ClubBanner'

export interface ClubAhorroContentProps {
  alliances?: IAlliance[]
  appView: IContentfulBasicEntry<Record<string, unknown>>
  contentEnd?: number
  contentStart?: number
  isLoading?: boolean
  isLoggedIn?: boolean
  isMobile?: boolean
  membershipData?: IMembershipBlock
  membershipLevel?: string
  unipayStatus?: 'preapproved' | 'holder' | null
  onClick?: (allianceId?: string) => void
  onClickBanner?: () => void
  setTagCoupon?: () => void
  version?: string
  userLevel?: string
}

export const ClubAhorroContent = ({
  alliances,
  appView,
  isLoading,
  isMobile,
  membershipData,
  membershipLevel,
  unipayStatus,
  onClick,
  onClickBanner,
  setTagCoupon,
  version,
  userLevel
}: ClubAhorroContentProps) => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const coupons = useCoupons()

  const { blocks, blocksDesktop } = appView
  const activeBlocks = isMobile ? blocks : blocksDesktop

  const blocksGap = useMemo(() => {
    if (isMobile && isLoggedIn) {
      return 32
    }

    if (isMobile) {
      return 30
    }

    if (isLoggedIn) {
      return 60
    }

    return 80
  }, [isMobile, isLoggedIn])

  const couponsEnable = useMemo(() => {
    return coupons.isLoading || isValidArrayWithData(coupons.data?.data?.data)
  }, [coupons.data, coupons.isLoading])

  const renderContent = (
    content: { [s: string]: BannersClubAhorro } | ArrayLike<BannersClubAhorro>,
    index: number
  ) => {
    const bannerContent: BannersClubAhorro[] = Object.values(content)

    const sectionIcon = bannerContent[0]?.sectionIcon?.fields.file.url
    const filteredImageBanners = bannerContent[0]?.['imageBanner'].filter(
      (imageBanner) => {
        if (imageBanner?.unimarcEnabledLevels && userLevel) {
          return imageBanner.unimarcEnabledLevels?.some(
            (unimarcEnabledLevel) => {
              return userLevel.toLowerCase().includes(unimarcEnabledLevel)
            }
          )
        }

        return imageBanner
      }
    )

    const filteredBannerContent: BannersClubAhorro = {
      ...bannerContent[0],
      imageBanner: filteredImageBanners
    }

    if (filteredBannerContent['bottomBanner']) return null
    if (filteredBannerContent['topBanner']) return null

    if (filteredBannerContent['layout'] === 'banner') {
      return (
        <ClubBanner
          content={filteredBannerContent}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          key={index}
          onClickAllianceBanner={onClick}
          onClickBanner={onClickBanner}
          sectionIcon={sectionIcon}
          unipayStatus={unipayStatus}
        />
      )
    }

    const [item] = bannerContent
    if (
      slugify(item?.['label']) === ClubUnimarcBannerLabels.CouponsBlock &&
      item?.['layout'] === ClubUnimarcBannerLayout.Carousel
    ) {
      if (!couponsEnable) return null

      const { ['title']: title } = item

      return (
        <ClubAhorroCoupons
          key={index}
          setTag={setTagCoupon}
          title={title}
          titlePrepend={
            <Icon
              customSize={26}
              name='Coupon6'
            />
          }
          version={version}
        />
      )
    }

    if (filteredBannerContent['layout'] === 'carousel') {
      const { banners: bannersCarousel, showButton } = getBannersCarousel(
        filteredBannerContent,
        router,
        onClick,
        alliances,
        isLoggedIn
      )
      if (bannersCarousel.length === 0) return null
      const label = filteredBannerContent?.['label']
      return (
        <PromotionsCarousel
          bannersCarousel={bannersCarousel}
          imageBanner={filteredImageBanners}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          key={index}
          label={label}
          marginTitle={isMobile ? '0 0 12px' : ''}
          pictureWidthMobile='160px'
          router={router}
          sectionIcon={sectionIcon}
          showButton={showButton || isLoggedIn}
          subtitle={filteredBannerContent.subtitle}
          title={filteredBannerContent.title}
        />
      )
    }

    if (filteredBannerContent?.layout === BannersLayoutEnum.Grid) {
      return (
        <ClubFeatureGrid
          imageBanner={filteredBannerContent.imageBanner}
          subtitle={filteredBannerContent.subtitle}
          title={filteredBannerContent.title}
        />
      )
    }
    if (
      filteredBannerContent?.layout === BannersLayoutEnum.Collage ||
      filteredBannerContent?.layout === 'three image grid'
    ) {
      return (
        <ClubFeatureCollage
          imageBanner={filteredBannerContent.imageBanner}
          isMobile={isMobile}
          sectionIcon={sectionIcon}
          subtitle={filteredBannerContent.subtitle}
          title={filteredBannerContent.title}
        />
      )
    }

    if (filteredBannerContent['layout'] === BannersLayoutEnum.Collage) {
      const banners = filteredBannerContent?.imageBanner?.map((banner) => {
        return {
          img: banner?.image?.fields?.file?.url
        }
      })
      return (
        <CollageBanner
          banners={banners}
          customCollageType='clubUnimarc3Banners'
        />
      )
    }

    return null
  }

  if (isLoading) {
    return (
      <Column
        alignItems='center'
        gap={48}
        margin='32px 0 0 0'
      >
        <Row
          alignItems='center'
          justifyContent='between'
        >
          <Column maxWidth='140px'>
            <Backbone
              borderRadius='18px'
              height={18}
              width={100}
            />
          </Column>

          <Column maxWidth='106px'>
            <Backbone
              borderRadius='18px'
              height={40}
              width={100}
            />
          </Column>
        </Row>

        <Row
          alignItems='center'
          justifyContent='between'
        >
          {times(4, (i) => {
            return (
              <Column
                key={i}
                maxWidth='270px'
              >
                <Backbone
                  borderRadius='18px'
                  height={170}
                  width={100}
                />
              </Column>
            )
          })}
        </Row>
      </Column>
    )
  }

  const renderBlocks = (blocks: unknown[]) => {
    if (isValidArrayWithData(blocks)) {
      return blocks.map((block, index) => {
        const contentType = block?.['sys']?.['contentType']?.['sys']?.['id']

        if (contentType === 'featureContentBanners') {
          return renderContent({ feature: block as BannersClubAhorro }, index)
        }

        return (
          <ClubBlock
            blockId={block?.['blockId']}
            description={block?.['description']}
            isMobile={isMobile}
            items={block?.['items']}
            key={block?.['blockId']}
            membershipData={membershipData}
            membershipLevel={membershipLevel}
          />
        )
      })
    }

    return null
  }

  return (
    <Column
      alignItems='center'
      gap={blocksGap}
    >
      {renderBlocks(activeBlocks as unknown[])}
    </Column>
  )
}
