import {
  breadcrumbJoin,
  dataLayerProductEvent,
  datalayerSimpleEvent,
  productProps
} from '@smu-chile/pkg-unimarc-hooks'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'

interface HandlePdpTagsProps {
  product: Product
  quantity: number
  salesChannel: string
  isSearch?: boolean
}

const handlePdpTags = ({
  product,
  quantity,
  salesChannel,
  isSearch
}: HandlePdpTagsProps) => {
  const breadcrumbIdCondition = isSearch
    ? 'vistos recientemente'
    : breadcrumbJoin.replace(/\//g, '_')
  const breadcrumbNameCondition = isSearch
    ? 'vistos recientemente'
    : breadcrumbJoin.replace(/\//g, '-')

  const listId = 'PDP_' + breadcrumbIdCondition
  const listName = 'PDP-' + breadcrumbNameCondition
  const items = [
    productProps({
      product,
      listId,
      listName,
      quantity
    })
  ]
  dataLayerProductEvent({
    event: 'view_item',
    items,
    saleschannel: salesChannel || process.env.NEXT_PUBLIC_SALES_CHANNEL || '39'
  })
}

const clickPlp = (labelVal: string | number, event: string) => {
  const clickedText = labelVal == '/' ? 'Inicio' : labelVal
  const category = breadcrumbJoin?.search(/búsqueda/i) == -1 ? 'PLP' : 'SRP'
  datalayerSimpleEvent({
    event,
    eventCategory: event == 'breadcrumb' ? breadcrumbJoin : category,
    eventLabel: clickedText.toString()
  })
}

export { clickPlp, handlePdpTags }
