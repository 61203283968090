import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Spacer,
  MobileModalWrapper,
  Button
} from '@smu-chile/pkg-unimarc-components'
import {
  generateBreadcrumbJoin,
  isValidArrayWithData,
  useCatalogFacets,
  useCategories,
  useContentful,
  usePagination,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import {
  contentBannerLegal,
  getInitialFilters,
  getQueryKeyToRequest,
  getSubCategoriesFilters,
  matchOptionKey,
  paginationCal,
  promotionsOfShelves,
  orderByOptions,
  isContinuosFetching
} from '../../shared/helpers'
import { useFilterCategories, useFilter, useOrder } from 'shared/utils/hooks'
import { OffersProps } from 'pages/ofertas/[...offers]'
import {
  BodyPageProducts,
  BodyPageProductsProps,
  ErrorPageV2Props,
  LegalTerms,
  MonetiseBannerProps
} from 'components'
import { useRouter } from 'next/router'
import { REACT_QUERY_GENERAL } from 'shared/constants/reactQuery'
import {
  CAMPAING_APP_CHIPS_UNIMARC,
  FACETS_AND_ORDERS_BY_FILTERS
} from 'shared/utils/constanst'
import { FacetsAndOrderByFiltersContent } from 'components/FacetsAndOrderByFiltersContent'

const ErrorPage = dynamic<ErrorPageV2Props>(
  import('..').then((mod) => {
    return mod.ErrorPageV2
  })
)

const MonetiseBanner = dynamic<MonetiseBannerProps>(
  import('components').then((mod) => {
    return mod.MonetiseBanner
  })
)
const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      tagName='main'
    >
      {children}
    </Container>
  )
}

const initialLimit = 49

export type TProductOfferPageProps = OffersProps & {
  bodyPageProductsProps?: BodyPageProductsProps
}
export const ProductOfferPage = ({
  bannerLegal,
  clusterId,
  clusterName,
  isMobile,
  reference,
  title,
  bodyPageProductsProps
}: TProductOfferPageProps) => {
  const router = useRouter()
  const [selectedTab, setSelectedTab] = useState(0)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const orderByFilterName = matchOptionKey(
    router?.query?.orderBy?.toString() ?? ''
  )
  // Pagination
  const page = parseInt(router?.query?.page as string) || 1
  const { offset, end: limit } = paginationCal({ page, limit: 50 })
  const nextLimit = offset + initialLimit
  const [countLimit, setCountLimit] = useState(offset + initialLimit)
  const [resources, setResources] = useState(Infinity)
  const [offSet, setOffSet] = useState(offset)
  const propsNavigation = usePagination({
    totalQuantity: resources,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: () => {
      const newCountLimit = nextLimit > limit ? limit : nextLimit
      setOffSet(offset)
      setCountLimit(newCountLimit)
    }
  })
  const continuosFetching: boolean = isContinuosFetching({
    countLimit,
    resources,
    limit
  })

  // Products
  const [products, setProducts] = useState([])

  // Facets
  const {
    applyFilters,
    compareAndSetInitialData,
    currentSelectedFilters,
    filterData
  } = useFilter(router) || {}
  const { currentCategory, handleClickCategoryPill, place } =
    useFilterCategories(router) || {}
  const initialFilters = getInitialFilters(router?.query)

  // Promotions chips
  const chipsData = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC[0]
    },
    reactQuery: REACT_QUERY_GENERAL
  })

  // Get data through services and reactQuery
  const reactQueryByOffer = {
    ...REACT_QUERY_GENERAL,
    enabled: offSet < countLimit
  }
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    {
      value: {
        ...currentSelectedFilters,
        ...applyFilters
      }
    },
    { value: currentCategory },
    { value: clusterId },
    { value: clusterName },
    { value: offset.toString() },
    { value: limit.toString() },
    { value: orderByFilterName }
  ])
  const { data: offerProductsData, ...offerProductProps } = useProductsSearch({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    clusterId: clusterId,
    clusterNames: clusterName,
    from: offset.toString(),
    to: limit.toString(),
    orderBy: orderByFilterName,
    reactQuery: {
      ...reactQueryByOffer,
      queryKey: keysToQuery
    }
  })
  const responseFacetsData = useCatalogFacets({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    clusterId: clusterId,
    clusterNames: clusterName,
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: keysToQuery
    }
  })

  // Breadcrumb
  const dataBreadCrumb = [
    {
      label: 'Inicio',
      url: '/',
      oneClick: () => {
        return null
      }
    },
    { label: `“${title}”`, url: '#' }
  ]

  // Get all chipsData available in contenful
  const { bannerImages, contentLegalParagraph, contentLegalTitle } =
    contentBannerLegal(bannerLegal)

  const urlLinkOffer = bannerLegal?.items?.[0]?.fields?.urlRedirect?.toString()
  const data = bannerLegal?.items?.[0]?.fields

  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: chipsData?.data })

  const isLoadingPage =
    offerProductProps.isLoading || offerProductProps.isFetching
  const isLoadingFacets =
    responseFacetsData?.isLoading || responseFacetsData?.isFetching
  const isLoadingPageAndFacets = isLoadingFacets || isLoadingPage
  const facetsFilterV2 = responseFacetsData?.data || {}
  const subCategories = getSubCategoriesFilters({
    facetsFilterV2
  })

  const {
    selectedRadio,
    disabledButton,
    onClickFilter,
    onClickRadioOrderByOption,
    onApplyFilterModal
  } = useOrder(router, setShowFiltersModal)

  // get all categories menu like with getCategories service
  const resultAisles = useCategories({
    level: 2,
    reactQuery: REACT_QUERY_GENERAL
  })

  const handleFetchProducts = () => {
    if (!offerProductProps.isLoading && continuosFetching) {
      setCountLimit((prev) => {
        const defaultLimit = limit > resources ? resources : limit
        const nextLimit = prev + initialLimit + 1
        return nextLimit > limit || nextLimit > resources
          ? defaultLimit
          : nextLimit
      })
    }
  }

  const handleToggleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }

  const dataOfBodyPageProducts: BodyPageProductsProps = {
    amountOfProducts: products?.length,
    bannerAndesMl: data,
    bannerImages,
    breadcrumbData: dataBreadCrumb,
    clusterName,
    dataPromotions,
    filterData: { ...filterData, isLoadingFacets: isLoadingPageAndFacets },
    facetsFilterV2: responseFacetsData?.data,
    isMobile,
    place,
    products,
    propsNavigation,
    reference,
    subCategories,
    title,
    totalQuantity: resources,
    selectedRadio,
    hasFacets: filterData.hasFilter,
    resultAisles: resultAisles,
    urlLink: urlLinkOffer,
    setCountLimit: handleFetchProducts,
    onClickFilter,
    onClickCategoryPill: handleClickCategoryPill,
    onClickRadioOrderByOption,
    handleOpenFiltersModal: setShowFiltersModal,
    ...bodyPageProductsProps
  }

  useEffect(() => {
    compareAndSetInitialData(facetsFilterV2)
  }, [facetsFilterV2])

  useEffect(() => {
    propsNavigation.setPage(page)
  }, [page])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  useEffect(() => {
    const {
      availableProducts = [],
      notAvailableProducts = [],
      resource: count
    } = offerProductsData || {}
    const newProducts = [...availableProducts, ...notAvailableProducts]
    if (count && parseInt(count) !== resources) {
      setResources(parseInt(count))
    }
    setProducts((prev) => {
      const prevData = continuosFetching ? prev : []
      return isLoadingPage && continuosFetching
        ? []
        : [...prevData, ...newProducts]
    })
  }, [offerProductsData, isLoadingPage, countLimit])

  // when service in client-side response with error property
  if (
    !isLoadingPage &&
    (offerProductProps?.isError ||
      (offerProductsData?.error && !offerProductsData?.resource))
  ) {
    return <ErrorPage errorType='400' />
  }

  // when service in client-side response a 404 or no data
  if (
    !isLoadingPage &&
    offerProductsData?.error &&
    !isValidArrayWithData(offerProductsData?.availableProducts)
  ) {
    return <ErrorPage errorType='404' />
  }

  return (
    <Main>
      <BigScreen>
        <Column alignItems='center'>
          {bannerImages && (
            <MonetiseBanner
              data={data}
              images={bannerImages}
              key={clusterId || clusterName}
              padding='32px 0 0 0'
              urlLink={urlLinkOffer}
            />
          )}
          {!bannerImages && <Spacer.Horizontal size={24} />}
          <Container
            backgroundColor={getGlobalStyle('--color-background-white')}
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='36px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Container>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column backgroundColor={getGlobalStyle('--color-base-white')}>
          {showFiltersModal && (
            <MobileModalWrapper
              blockId='facetsAndOrderByFilters'
              body={
                <FacetsAndOrderByFiltersContent
                  amountOfProducts={products?.length}
                  facetFilterProps={filterData}
                  isMobile={isMobile}
                  onClickDetectTab={setSelectedTab}
                  orderByProps={{
                    onClick: onClickRadioOrderByOption,
                    options: orderByOptions,
                    selected: selectedRadio
                  }}
                />
              }
              headerLeftElement={
                selectedTab === 0 &&
                filterData.hasFilter && (
                  <Column
                    flexGrow={0}
                    width='auto'
                  >
                    <Button
                      color={getGlobalStyle('--color-primary-light-red')}
                      fontSize='12px'
                      fontWeight='600'
                      height='initial'
                      label='Limpiar'
                      minWidth='auto'
                      onClick={filterData.handleClearOnClick}
                      type='plain'
                    />
                  </Column>
                )
              }
              headerTitle={FACETS_AND_ORDERS_BY_FILTERS.TITLE}
              iconSize={20}
              isEnableButton={!disabledButton || filterData?.enableButton}
              modalConfigsProps={{
                toggle: handleToggleFiltersModal,
                isOpen: showFiltersModal,
                toggleOutside: handleToggleFiltersModal
              }}
              onApply={() => {
                if (selectedTab === 1) return onApplyFilterModal()
                if (selectedTab === 0)
                  return filterData.handleApplyOnClick(handleToggleFiltersModal)
                return null
              }}
              onClose={handleToggleFiltersModal}
            />
          )}
          <BodyPageProducts {...dataOfBodyPageProducts} />
          <LegalTerms
            paragraph={contentLegalParagraph}
            title={contentLegalTitle}
          />
        </Column>
      </SmallScreen>
    </Main>
  )
}
