import {
  FacetsFilterV2 as FacetsData,
  ICategory,
  PriceRange,
  PromotionsOnly
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { filterIconDictionary, normalizeQuery } from 'shared/helpers'
import {
  isObjectEmpty,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { NextRouter } from 'next/router'
import { TFacetData, TFormatedData } from '../types'
import { IPostFacetsResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostFacets'
import { ALLOWED_FACETS } from 'shared/utils/constanst'

type UpdateQueryParamsFiltersProps = {
  arraySelectedFilters: GetData[]
  params: Record<string, string[]>
  isClearAll?: boolean
  router: NextRouter
  withoutProducts: boolean
}
type ValueOf<T> = T[keyof T]
export type GetDataStatus = 'active' | 'initial' | 'selected'

export type FacetsDataValues = ValueOf<FacetsData>
export type GetData = {
  data?: ICategory[] | string[] | FacetsDataValues
  from?: number
  to?: number
  id?: string
  icon?: string
  name?: string
  property?: keyof FacetsData | ''
  status?: GetDataStatus
  quantity?: number
}

export type SelectedFiltersReturnType = Record<
  keyof IPostFacetsResponse,
  string[]
>

export type ModifiedSelectedFiltersReturnType = Omit<
  SelectedFiltersReturnType,
  'priceRange' | 'category1' | 'category2' | 'category3' | 'promotionsOnly'
> & {
  priceRangeFrom?: string[]
  priceRangeTo?: string[]
  promotionsOnly?: string[]
}

type CreateLeadProps = (arr: Array<string>, maxLength?: number) => string

/**
 * Adapts filter data based on provided key, data and query parameters.
 *
 * @param {keyof FacetsData} key - The key representing the type of data to format.
 * @param {FacetsDataValues} data - The data values for the key.
 * @param {Object} [queryParams={}] - The query parameters to check against for filter status.
 * @returns {TFormatedData} - The formatted data for the given key and data values.
 */

export const adapterFilterData = (
  key: keyof FacetsData,
  data: FacetsDataValues,
  arraySelectedFilters: GetData[],
  queryParams: object = {}
): TFormatedData => {
  const isDataArray = isValidArrayWithData(data)
  const getIsFiltering = () => {
    if (queryParams[key]) return !!queryParams[key]
    if (key === 'priceRange') {
      return !!queryParams['priceRangeFrom']
    }
    return false
  }
  const isFiltering = getIsFiltering()
  let getData: GetData[] = []
  let getLead = ''
  if (isDataArray) {
    let status: GetDataStatus = 'initial'

    const validFacetsDataValues = data as ICategory[] | string[]
    getData = validFacetsDataValues.map((facet: ICategory | string) => {
      if (typeof facet === 'object') {
        if (queryParams[key]) {
          status = queryParams[key].includes(facet?.name) ? 'active' : 'initial'
        }
        return {
          data: [facet],
          status,
          icon: filterIconDictionary(facet?.value),
          name: facet?.name
        }
      }
      return { data: [facet], status, icon: filterIconDictionary(facet) }
    })
    getLead = queryParams[key] ? createLead(queryParams[key]) : ''
  }

  const isValidDataPromotionsOnly = (data: FacetsDataValues) => {
    return (
      data &&
      typeof data === 'object' &&
      Object.prototype.hasOwnProperty.call(data, 'selected') &&
      Object.prototype.hasOwnProperty.call(data, 'quantity')
    )
  }
  // check if data is and object with from and to props
  const isPriceRange = data?.['from'] && data?.['to']
  const isPromotionsOnly = isValidDataPromotionsOnly(data)
  if (isPriceRange) {
    let status: GetDataStatus = 'initial'
    let selected = false
    const selectedPriceRange =
      isValidArrayWithData(arraySelectedFilters) &&
      arraySelectedFilters.find((facet) => {
        return facet.property === 'priceRange'
      })

    const currentData = selectedPriceRange || data
    getLead = `${currentData?.['from']}-${currentData?.['to']}`

    if (queryParams['priceRangeFrom']) {
      status = 'active'
      selected = true
    }
    getData.push({
      data: [
        {
          ...(currentData as PriceRange),
          id: `${currentData?.['from']}-${currentData?.['to']}`,
          selected
        }
      ],
      status: status,
      name: key
    })
  }

  if (isPromotionsOnly) {
    let status: GetDataStatus = 'initial'
    let selected = false
    const selectedPromotionsOnly =
      isValidArrayWithData(arraySelectedFilters) &&
      arraySelectedFilters.find((facet) => {
        return facet.property === 'promotionsOnly'
      })
    const currentData = selectedPromotionsOnly || data
    getLead = ''

    if (queryParams['promotionsOnly']) {
      status = 'active'
      selected = true
    }
    getData.push({
      data: [
        {
          ...(currentData as PromotionsOnly),
          id: 'promotionsOnly',
          selected
        }
      ],
      status: status,
      name: key
    })
  }
  return {
    data: getData,
    isFiltering,
    lead: getLead,
    property: key
  }
}

/**
 * Creates a lead string by joining the array elements with commas.
 * If the resulting string is longer than the provided maxLength, it trims the string and adds '...'.
 *
 * @param {Array<string>} arr - The array of strings to be joined.
 * @param {number} - The maximum length for the resulting string.
 * @returns {string} - The lead string based on the given array and maxLength.
 */
export const createLead: CreateLeadProps = (
  arr: Array<string>,
  maxLength = 35
): string => {
  const joinedString = arr.join(', ')

  if (joinedString.length <= maxLength) {
    return joinedString
  }

  const lead = joinedString.substring(0, maxLength).trim()
  const formatLead = lead.endsWith(',')
    ? lead.substring(0, lead.length - 1) + '...'
    : lead + '...'

  return formatLead
}

function isIcategoryArray(data: TFacetData): boolean {
  const filterData = data?.[0] as ICategory
  return isValidArrayWithData(data) && !!filterData?.name
}

/**
 * Finds the icon name based on the provided filter string.
 * @function
 * @param {TFacetData} data - The data to search through.
 * @param {string} filter - The filter string to match against.
 * @returns {string} - Returns the icon name if found, an empty string otherwise.
 */
export function findIconName(data: TFacetData, filter: string): string {
  // Checking if data is an array of ICategory objects
  if (isIcategoryArray(data)) {
    const validData = data as ICategory[]
    // Finding the ICategory object that matches the filter string
    const found = validData.find((initialFilterData) => {
      return initialFilterData.name === filter
    })
    // Returning the icon name if found, an empty string otherwise
    return found?.value || ''
  }
  return ''
}

export function findId(data: TFacetData, filter: string): string {
  // Checking if data is an array of ICategory objects
  if (isIcategoryArray(data)) {
    const validData = data as ICategory[]
    // Finding the ICategory object that matches the filter string
    const found = validData.find((initialFilterData) => {
      return initialFilterData.name === filter
    })
    // Returning the icon name if found, an empty string otherwise
    return found?.id || ''
  }
  return ''
}
type TFormatFacetToLowerCaseWithDashes = Record<string, string | string[]>

/**
 * Transforms the keys of the object to lowercase and replaces spaces with dashes.
 *
 * @param {TFormatFacetToLowerCaseWithDashes} data - Input object with the keys to transform.
 * @returns {Record<string, string | string[]>} Object with the transformed keys.
 */
export const formatFacetToLowerCaseWithDashes = (
  data: TFormatFacetToLowerCaseWithDashes
): Record<string, string | string[]> => {
  const result: TFormatFacetToLowerCaseWithDashes = {}

  for (const key in data) {
    if (key === 'orderBy') {
      result[key] = data[key]
    } else {
      result[key] = isValidArrayWithData(data[key])
        ? (data[key] as string[]).map((item) => {
            return item.toLowerCase().replace(/ /g, '-')
          })
        : (data[key] as string).toLowerCase().replace(/ /g, '-')
    }
  }
  return result
}

/**
 * Formats the currently selected filters by comparing them with the previous initial data.
 *
 * @param {Object} previousInitialDataRef - Reference to the previous initial data.
 * @param {Object} previousInitialDataRef.current - The current initial data.
 * @param {ModifiedSelectedFiltersReturnType | Record<string, never>} currentSelectedFilters - The currently selected filters.
 * @returns {ModifiedSelectedFiltersReturnType} - The transformed selected filters with the corresponding names.
 */
export const formatFiltersFromQueryParams = (
  previousInitialDataRef: {
    current: IPostFacetsResponse | Record<string, never>
  },
  currentSelectedFilters:
    | ModifiedSelectedFiltersReturnType
    | Record<string, never>
) => {
  const transformedFilters: Partial<ModifiedSelectedFiltersReturnType> = {}

  for (const key of Object.keys(currentSelectedFilters)) {
    if (key === 'categories') continue

    const currentFilterValues = currentSelectedFilters[key]
    const previousData = previousInitialDataRef.current[key]
    if (
      previousData &&
      Array.isArray(previousData) &&
      Array.isArray(currentFilterValues)
    ) {
      transformedFilters[key] = currentFilterValues.map((filterValue) => {
        const matchedFilter = previousData.find((item: ICategory) => {
          return item.value === filterValue
        })

        return matchedFilter ? matchedFilter.name : filterValue
      })
    } else {
      transformedFilters[key] = currentFilterValues
    }
  }

  return transformedFilters as ModifiedSelectedFiltersReturnType
}

/**
 * Merges existing query parameters with new filter values and returns a URL-encoded query string.
 *
 * @param existingParams - An object representing the existing query parameters.
 * @param newFilters - An object representing the new filters to be applied.
 * @returns A URL-encoded query string.
 *
 * @example
 * // Existing URL parameters
 * const existingParams: Record<string, string | string[]> = {
 *   page: '2',
 *   sort: 'name',
 * };
 *
 * // New filters to apply
 * const newFilters: Record<string, string[]> = {
 *   brands: ['Almond Dream', 'Cocoa Raff'],
 *   container: ['Pan'],
 *   foodWorld: ['Libre de Huevo', 'Libre de Soya'],
 *   warningStamps: ['Dos sellos'],
 * };
 *
 * // Generates a query string by combining existing parameters with new filters
 * const queryString: string = createQueryString(existingParams, newFilters);
 * // queryString is "page=2&sort=name&brands=Almond+Dream,Cocoa+Raff&container=Pan&foodWorld=Libre+de+Huevo,Libre+de+Soya&warningStamps=Dos+sellos"
 */
export const createQueryString = (
  arraySelectedFilters: GetData[],
  existingParams: Record<string, string | string[]>,
  newFilters: Record<string, string | string[]>,
  withoutProducts: boolean
): string => {
  // method to replaced and normalize string
  const newFiltersFormatted = formatFacetToLowerCaseWithDashes(newFilters)
  // Helper function to encode URI components including apostrophes
  const encode = (value: string): string => {
    return encodeURIComponent(value).replace(/[!',()*]/g, (character) => {
      return `%${character.charCodeAt(0).toString(16)}`
    })
  }

  let combinedParams: Record<string, string | string[]> = {
    ...newFiltersFormatted
  }
  if (existingParams?.q && !combinedParams?.q) {
    combinedParams.q = existingParams?.q
  }

  if (!withoutProducts) {
    const retainedExistingParams = Object.fromEntries(
      Object.entries(existingParams).filter(([key]) => {
        return !ALLOWED_FACETS.includes(key) || key === 'page' || key === 'sort'
      })
    )

    combinedParams = {
      ...combinedParams,
      ...retainedExistingParams,
      ...newFiltersFormatted
    }
  } else {
    combinedParams = { ...combinedParams, ...newFiltersFormatted }
  }
  // clean combinedParams of priceRangeFrom & priceRangeTo
  // if doesnt exist in arraySelectedFilters
  const cleanedCombinedParams = updateCombinedParams(
    combinedParams,
    arraySelectedFilters
  )
  // Apply new filters, overwriting any existing ones.
  Object.keys(newFiltersFormatted)
    .filter((key) => {
      return key !== 'q'
    })
    .forEach((key) => {
      cleanedCombinedParams[key] = (newFiltersFormatted[key] as string[])
        .map(encode)
        .join(',')
    })
  Object.keys(cleanedCombinedParams).forEach((key) => {
    const value = cleanedCombinedParams[key]
    const valueArray = Array.isArray(value) ? value : [value]
    cleanedCombinedParams[key] = valueArray.map(encode).join(',')
  })
  // Construct the final query string with all parameters URL-encoded.
  const queryComponents = Object.keys(cleanedCombinedParams).map((key) => {
    const encodedKey = encode(key)
    // The values are already encoded individually, so we can directly use them here.
    return `${encodedKey}=${cleanedCombinedParams[key]}`
  })
  return queryComponents.join('&')
}

const updateCombinedParams = (
  combinedParams: Record<string, string | string[]>,
  arraySelectedFilters: GetData[]
) => {
  // Create a copy of combinedParams to avoid direct mutations
  const updatedParams = { ...combinedParams }

  // Check if arraySelectedFilters contains an object with the 'priceRange' property
  const hasPriceRange = arraySelectedFilters.some((filter) => {
    return filter.property === 'priceRange'
  })
  // Check if arraySelectedFilters contains an object with the 'promotionsOnly' property
  const hasPromotionsOnly = arraySelectedFilters.some((filter) => {
    return filter.property === 'promotionsOnly'
  })

  // If 'priceRange' is not present, remove 'priceRangeFrom' and 'priceRangeTo' from updatedParams
  if (!hasPriceRange) {
    delete updatedParams.priceRangeFrom
    delete updatedParams.priceRangeTo
  }

  // If 'promotionsOnly' is not present, remove 'promotionsOnly' from updatedParams
  if (!hasPromotionsOnly) {
    delete updatedParams.promotionsOnly
  }

  // Return the updated object
  return updatedParams
}

/**
 * Removes specified query parameters from a given set of query parameters
 * and filters out empty values.
 *
 * This function iterates over the query parameters and removes any keys
 * specified in the `paramsToRemove` array. Additionally, it filters out
 * parameters with empty string values or arrays containing only empty strings.
 *
 * @param {Record<string, string | string[]>} queryParams - The original set of query parameters.
 * @param {string[]} paramsToRemove - An array of parameter keys to be removed from the queryParams.
 * @returns {Record<string, string | string[]>} A filtered set of query parameters with the specified parameters removed and empty values excluded.
 *
 * @example
 * // Remove a single query parameter and exclude empty values
 * const originalParams = { a: "1", b: "2", c: "", d: ["", "valid"] };
 * const paramsToExclude = ["b"];
 * const newParams = removeQueryParams(originalParams, paramsToExclude);
 * console.log(newParams); // Output: { a: "1", d: ["valid"] }
 *
 * @example
 * // Remove multiple query parameters and exclude empty values
 * const originalParams = { a: "1", b: "2", c: "", d: [""] };
 * const paramsToExclude = ["b", "c"];
 * const newParams = removeQueryParams(originalParams, paramsToExclude);
 * console.log(newParams); // Output: { a: "1" }
 *
 * @example
 * // Handle empty arrays and empty strings
 * const originalParams = { a: "", b: [""] };
 * const paramsToExclude = [];
 * const newParams = removeQueryParams(originalParams, paramsToExclude);
 * console.log(newParams); // Output: {}
 */

export const removeQueryParams = (
  queryParams: Record<string, string | string[]>,
  paramsToRemove: string[]
): Record<string, string | string[]> => {
  const filteredQueryParams: Record<string, string | string[]> = {}

  for (const [key, value] of Object.entries(queryParams)) {
    if (paramsToRemove.includes(key)) continue

    const isNonEmptyString = (val: string): boolean => {
      return val.trim() !== ''
    }
    const isNonEmptyArray = (arr: string[]): boolean => {
      return arr.some(isNonEmptyString)
    }

    if (typeof value === 'string' && isNonEmptyString(value)) {
      filteredQueryParams[key] = value
    } else if (Array.isArray(value) && isNonEmptyArray(value)) {
      filteredQueryParams[key] = value.filter(isNonEmptyString)
    }
  }

  return filteredQueryParams
}

/**
 * Checks if any filter is currently active (selected) in the provided list of filters.
 *
 * @param {GetData[]} activeFilters - An array of filter objects to be checked.
 * @returns {boolean} Returns true if at least one filter has its status set to 'selected', otherwise false.
 *
 * @example
 * // Example of a filter object
 * const filters = [
 *   { id: "1", name: "Filter1", status: "selected" },
 *   { id: "2", name: "Filter2", status: "initial" }
 * ];
 *
 * // Usage
 * const isActive = isFilterActive(filters);
 * console.log(isActive); // Output: true
 */
export const isFilterActive = (activeFilters: GetData[]) => {
  return activeFilters.some((facet) => {
    if (facet.property === 'promotionsOnly') {
      return facet.status === 'initial'
    }
    return facet.status === 'selected'
  })
}

/**
 * Extracts and groups the names of selected filters from an array of formatted filter data.
 *
 * @param {TFormatedData[]} formatDataArray - An array of formatted filter data.
 * @returns {SelectedFiltersReturnType} An object containing the grouped names of selected filters.
 *
 * @example
 * // Example of formatted filter data
 * const formattedData = [
 *   {
 *     property: 'foodWorld',
 *     data: [{ name: 'Red', status: 'active' }, { name: 'Blue', status: 'inactive' }]
 *   },
 *   {
 *     property: 'container',
 *     data: [{ name: 'Small', status: 'inactive' }, { name: 'Large', status: 'active' }]
 *   }
 * ];
 *
 * // Usage
 * const selectedFilters = getSelectedFilters(formattedData);
 * console.log(selectedFilters); // Output: { color: ['Red'], size: ['Large'] }
 */
export const getSelectedFilters = (formatDataArray: TFormatedData[]) => {
  return formatDataArray.reduce((acc, filter: TFormatedData) => {
    const selected = filter.data
      .filter((filterData) => {
        return filterData.status === 'active'
      })
      .map((filterData) => {
        if (filterData.name === 'priceRange') {
          return filter.lead
        }
        return filterData?.name
      })
    if (isValidArrayWithData(selected)) {
      acc[filter.property] = selected
    }
    return acc
  }, {} as SelectedFiltersReturnType)
}

/**
 * Updates the URL query parameters based on the provided params, removing specific parameters.
 *
 * @param {UpdateQueryParamsFiltersProps} { params, router } - An object containing the params to update and the Next.js router object.
 *
 * @example
 * // Example of usage in a Next.js component
 * const router = useRouter();
 * const newParams = { search: 'query', sort: 'desc' };
 *
 * // Update query parameters in URL
 * updateQueryParamsFilters({ params: newParams, router });
 */
export const updateQueryParamsFilters = ({
  arraySelectedFilters,
  params,
  router,
  isClearAll = false,
  withoutProducts
}: UpdateQueryParamsFiltersProps) => {
  const { query, asPath } = router || {}
  // Exit early if router.query is undefined
  if (!query) return
  const pathname = asPath.split('?')[0]
  // clear all the queryParams from url
  if (isClearAll) {
    const newQuery = normalizeQuery({
      ...(query.q && { q: query.q }),
      ...(query.page && { page: query.page }),
      ...(query.orderBy && { orderBy: query.orderBy }),
      ...(query.suggestions && { suggestions: query.suggestions })
    })

    router.push({ pathname, query: newQuery }, undefined, {
      shallow: true
    })
    return
  }
  // Check if params is a non-null object (and not an array)
  if (!Array.isArray(params) && !isObjectEmpty(params)) {
    let queryString = ''
    const paramsToRemove = ['offers']
    if (pathname.includes('/category/')) {
      paramsToRemove.push('categories')
    }
    // Remove 'categories', 'offers', and 'page' query parameters
    // Creating a new obj of query
    const cleanedExistingParams = removeQueryParams(query, paramsToRemove)
    const cleanedNewParams = removeQueryParams(params, paramsToRemove)

    // Check if params has any keys, if so, make a validQueryString and push
    // it to url
    if (isValidArrayWithData(Object.keys(cleanedNewParams))) {
      queryString = createQueryString(
        arraySelectedFilters,
        cleanedExistingParams,
        cleanedNewParams,
        withoutProducts
      )
      router.push({ pathname, search: queryString }, undefined, {
        shallow: true
      })
    }
  }
}

/**
 * Extracts and flattens all selected filter values from a given object into a single array.
 * Accepts a partial representation of the SelectedFiltersReturnType.
 *
 * @param {Partial<SelectedFiltersReturnType>} arr - An object containing various filter categories and their selected values.
 * @returns {string[]} An array of strings representing all the selected filter values.
 *
 * @example
 * // Example usage
 * const selectedFilters = {
 *   brands: ['Brand1', 'Brand2'],
 *   volume: ['Volume1']
 * };
 *
 * const allSelectedFilters = getArraySelectedFilters(selectedFilters);
 * console.log(allSelectedFilters); // Output: ['Brand1', 'Brand2', 'Volume1']
 */
export const getArraySelectedFilters = (
  arr: Partial<SelectedFiltersReturnType>
): string[] => {
  const arrFilters: string[] = []
  const arrKeys = Object.keys(arr)
  if (isValidArrayWithData(arrKeys)) {
    arrKeys.forEach((key) => {
      arrFilters.push(...arr[key as keyof SelectedFiltersReturnType])
    })
  }
  return arrFilters
}

/**
 * Processes an array of formatted filter data to determine and set the 'isFiltering' status for each filter.
 * 'isFiltering' is set to true if the filter has any items with a status of 'active'.
 *
 * @param {TFormatedData[]} filters - An array of formatted filter data.
 * @returns {TFormatedData[]} An array of formatted filter data with updated 'isFiltering' status.
 *
 * @example
 * // Example of formatted filter data
 * const filters = [
 *   {
 *     property: 'color',
 *     isFiltering: false,
 *     lead: '',
 *     data: [{ name: 'Red', status: 'active' }, { name: 'Blue', status: 'inactive' }]
 *   },
 *   {
 *     property: 'size',
 *     isFiltering: false,
 *     lead: '',
 *     data: [{ name: 'Small', status: 'inactive' }, { name: 'Large', status: 'inactive' }]
 *   }
 * ];
 *
 * // Usage
 * const updatedFilters = activeFiltering(filters);
 * // updatedFilters[0].isFiltering will be true, and updatedFilters[1].isFiltering will be false
 */
export const activeFiltering = (filters: TFormatedData[]) => {
  if (isValidArrayWithData(filters)) {
    return filters.map((filter) => {
      const data = filter.data.filter((item) => {
        return item.status === 'active'
      })
      filter.isFiltering = isValidArrayWithData(data)
      return filter
    })
  }
  return []
}

/**
 * Transforms each string in the facets object: converts to lowercase and replaces spaces with hyphens.
 *
 * @param {SelectedFiltersReturnType} facets - An object containing arrays of string values representing filters.
 * @returns {Record<string, never> | SelectedFiltersReturnType} - An object with the same structure as the input,
 *          but with each string transformed to lowercase and spaces replaced by hyphens.
 *
 * @example
 * const facets = {
 *   brands: ["Almond Dream", "Cocoa Raff"],
 *   warningStamps: ["High Sugar"],
 *   // ... other facets
 * };
 * const transformedFacets = transformFacetsDataString(facets);
 * // transformedFacets is now:
 * // {
 * //   brands: ["almond-dream", "cocoa-raff"],
 * //   warningStamps: ["high-sugar"],
 * //   // ... other transformed facets
 * // }
 */
export const transformFacetsDataString = (
  facets: Partial<ModifiedSelectedFiltersReturnType>
): Record<string, never> | ModifiedSelectedFiltersReturnType => {
  return Object.keys(facets).reduce((acc, key) => {
    const value = facets[key]
    // check if value is an array or a string and process it accordingly
    if (Array.isArray(value)) {
      acc[key] = value.map((val) => {
        return val?.toLowerCase()
      })
    } else if (typeof value === 'string') {
      acc[key] = value.toLowerCase()
    }

    return acc
  }, {} as ModifiedSelectedFiltersReturnType)
}

export const processPriceAndPromoFacets = (selectedFilters: {
  [key: string]: string[] | string
}) => {
  // Destructure the object to extract priceRange, promotionsOnly, and other filters
  const { priceRange, ...otherProps } = selectedFilters

  // Initialize the output object
  const output: ModifiedSelectedFiltersReturnType = {
    ...(otherProps as Omit<SelectedFiltersReturnType, 'priceRange'>)
  }
  // Process the price range if it is present and valid
  if (priceRange && isValidArrayWithData(priceRange)) {
    const [from, to] = priceRange[0].split(/[,-]/).map((str) => {
      return str.trim()
    })
    output.priceRangeFrom = [from]
    output.priceRangeTo = [to]
  }
  // Check and set the "promotionsOnly" property
  if (
    selectedFilters['promotionsOnly'] &&
    isValidArrayWithData(selectedFilters['promotionsOnly'])
  ) {
    output['promotionsOnly'] = ['true']
  }

  // Return the modified object
  return output
}
/**
 * Determines if the formatted data object for facets is empty.
 *
 * This function checks if every filter in the formatted data array has an empty data array.
 *
 * @param {TFormatedData[]} formatedData - An array of formatted data objects for facets.
 * @returns {boolean} - Returns `true` if every filter's data array is empty, otherwise `false`.
 *
 * @example
 * // Define a sample formatted data array
 * const sampleFormattedData = [
 *   { property: 'brands', data: [{ data: [] }] },
 *   { property: 'volume', data: [{ data: [] }] },
 * ];
 *
 * // Check if formatted data is empty
 * const isEmpty = isFacetsDataEmpty(sampleFormattedData);
 * console.log(isEmpty); // Output: true
 */
export const isFacetsDataEmpty = (formatedData: Partial<TFormatedData[]>) => {
  return formatedData.every((filter) => {
    return !isValidArrayWithData(filter.data[0]?.data)
  })
}

type FilterObject = {
  [key: string]: string[] | { from: number; to: number }
}
/**
 * Removes duplicate values from each array within a filter object.
 *
 * This function iterates over each key of the provided object. If the value under
 * a key is an array, it removes duplicate values from that array.
 *
 * @param {FilterObject} obj - An object where each property is an array of strings.
 * @returns {SelectedFiltersReturnType} - An object with the same structure as the input,
 *         but with duplicate values removed from each array.
 *
 * @example
 * // Define a sample filter object with duplicate values
 * const filterObject = {
 *   brands: ['Brand1', 'Brand2', 'Brand1'],
 *   container: ['Container1', 'Container1', 'Container2']
 * };
 *
 * // Remove duplicates from the object
 * const uniqueFilterObject = removeDuplicatesFromObject(filterObject);
 * console.log(uniqueFilterObject);
 * // Output: { brands: ['Brand1', 'Brand2'], container: ['Container1', 'Container2'] }
 */
export const removeDuplicatesFromObject = (
  obj: Partial<FilterObject>
): SelectedFiltersReturnType => {
  const newObj: FilterObject = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (Array.isArray(newObj[key])) {
      newObj[key] = Array.from(new Set(newObj[key] as string[]))
    }
  })

  return newObj as SelectedFiltersReturnType
}

/**
 * Removes invalid items from a FacetsData object.
 *
 * Iterates over each property of the given FacetsData object. If the property is an array,
 * it filters out items with a name of '.' and a value of '-'.
 * If the property is not an array, it is copied as-is to the new object.
 *
 * @param {FacetsData} data - The original FacetsData object to be cleaned.
 * @returns {FacetsData} - A new FacetsData object with invalid items removed.
 *
 * @example
 * // Example of a FacetsData object with invalid items
 * const facetsDataWithInvalidItems = {
 *   brands: [{ name: 'Brand1', value: 'brand1' }, { name: '.', value: '-' }],
 *   container: [{ name: 'Container1', value: 'container1' }]
 * };
 *
 * // Removing invalid items
 * const cleanedFacetsData = removeInvalidItems(facetsDataWithInvalidItems);
 * console.log(cleanedFacetsData);
 * // Output: { brands: [{ name: 'Brand1', value: 'brand1' }], container: [{ name: 'Container1', value: 'container1' }] }
 */
export const removeInvalidItems = (
  data: Partial<IPostFacetsResponse>
): IPostFacetsResponse => {
  const newData: IPostFacetsResponse = {}

  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      newData[key] = data[key].filter((item) => {
        return item.name !== '.' || item.value !== '-'
      })
    } else {
      newData[key] = data[key]
    }
  })

  return newData
}

export const getQueryParamValue = (url: string, paramName: string) => {
  let urlObj: Partial<URL> = {}
  try {
    urlObj = new URL(url, window.location.origin)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return urlObj?.searchParams.get(paramName)
}
