import {
  AxiosRequestHeaders,
  getSearchSuggestionsNewBff,
  QueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import {
  getDataApplyFilters,
  getInitialFilters,
  getQueryKeyToRequest
} from '../getDataFilters'
import { paginationCal } from '../paginationCal'
import { postProductsSearch } from '@smu-chile/pkg-unimarc-hooks/services/postProductsSearch'
import { IDynamicObject } from '../../interfaces/IDynamicObject'
import { postFacets } from '@smu-chile/pkg-unimarc-hooks/services/postFacets'
import { cleanSearching } from '../cleanSearching'

interface GetSearchDataProps {
  query: string
  page: number
  headers: AxiosRequestHeaders
  params: IDynamicObject
}

export const getSearchData = async ({
  query = '',
  page = 1,
  headers = {},
  params
}: GetSearchDataProps) => {
  const queryClient = new QueryClient()
  const cleanedQuery = decodeURI(query)
  const cleaneadQueryWithoutDashes = cleanSearching(cleanedQuery)
  const queryWithoutDashes = query.replace(/-/g, ' ')
  const { offset, end } = paginationCal({ page, limit: 50 })
  const initialLimit = 0
  const limit = end - initialLimit
  const initialFilters = getInitialFilters(params)
  const orderBy = params?.orderBy?.toString() ?? ''
  const { brand, format, category } = getDataApplyFilters(initialFilters)
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    {
      value: JSON.parse(
        JSON.stringify({
          q: [cleanedQuery],
          suggestions: params?.suggestions ? ['true'] : undefined
        })
      )
    },
    {
      value: null
    },
    { value: cleanedQuery },
    { value: offset.toString() },
    { value: limit.toString() },
    { value: orderBy }
  ])

  const intelliSearchKeyName = ['productsSearch-client', keysToQuery]
  const keyToSearchSuggestions = ['searchSuggestions', query]
  const keyToCatalogFacets = ['CatalogFacets', keysToQuery]

  if (params?.suggestions === 'true') {
    await Promise.all(
      [
        queryClient.prefetchQuery(intelliSearchKeyName, () => {
          return postProductsSearch({
            brands: brand && [brand],
            categories: category,
            format: format && [format],
            from: offset.toString(),
            headers,
            orderBy,
            searching: cleaneadQueryWithoutDashes,
            to: limit.toString()
          })
        }),
        queryClient.prefetchQuery(keyToSearchSuggestions, () => {
          return getSearchSuggestionsNewBff({ product: query, site: '' })
        }),
        queryClient.prefetchQuery(keyToCatalogFacets, () => {
          return postFacets({
            categories: category,
            promotionsOnly: false,
            searching: queryWithoutDashes
          })
        })
      ].filter(Boolean)
    )
  }
  const intelliSearchData = queryClient.getQueryData(intelliSearchKeyName)

  return {
    intelliSearchData,
    queryClient
  }
}
